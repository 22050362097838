<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="37"
    viewBox="0 0 33 37"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.9245 1.22687C7.00716 1.97503 7.25964 1.73728 7.25964 4.67991C7.25964 7.00254 7.31475 7.23452 7.82035 7.03875C9.85975 6.24798 12.4092 5.81287 15.7504 5.68535C19.0949 5.55782 19.9917 5.64113 22.6392 6.32488C24.3133 6.75711 26.222 7.33641 26.8811 7.61196C28.0784 8.11276 28.0788 8.1134 27.8779 9.65263C27.1326 15.3652 23.7443 22.7269 19.8847 27.0197C19.1086 27.8825 18.4739 28.6701 18.4739 28.7701C18.4739 28.8697 19.3182 29.5246 20.3502 30.2254L22.2265 31.4996L23.3489 30.3176C28.4354 24.9601 32.2005 16.0938 32.7817 8.10507L32.9829 5.33803L29.9632 3.80946C28.308 2.99467 25.8112 2.07563 24.4014 1.70973C21.1028 0.853598 14.1772 0.605603 10.9245 1.22687ZM2.93414 3.8917C0.144988 5.29573 0.10718 5.36237 0.354214 8.42547C0.880964 14.9531 3.6432 22.2998 7.59863 27.6935C9.93056 30.8736 15.4666 36.0241 16.5521 36.0241C17.0116 36.0241 20.4037 33.5083 20.3845 33.1815C20.3781 33.0709 19.7587 32.5916 19.0083 32.1167C17.0548 30.8803 13.556 27.5977 11.9485 25.4926C7.89084 20.1793 5.7377 14.486 4.94662 6.97883L4.53617 3.08556L2.93414 3.8917Z"
      fill="#0C2D5E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7132 9.63307C11.2847 10.5785 8.625 17.2175 8.625 17.3414C8.625 17.4167 8.82357 17.458 9.06628 17.433C9.50055 17.3886 9.52759 17.3388 10.7369 14.3581C11.4129 12.6917 12.0217 11.2367 12.0896 11.1247C12.1757 10.9829 12.8653 12.5357 14.3641 16.2465C16.9878 22.7425 16.9611 22.6852 17.3446 22.6269C17.5788 22.5913 17.7812 22.2333 18.3031 20.932C18.667 20.0246 19.078 19.1969 19.2168 19.0925C19.4038 18.9517 20.1474 18.9028 22.1045 18.9028C24.8731 18.9028 24.9876 18.8733 24.5606 18.2723C24.398 18.0433 24.1201 18.0235 21.6287 18.0642C18.4032 18.117 18.4592 18.0919 17.7985 19.7817C17.5809 20.3381 17.3631 20.8187 17.3143 20.8498C17.2656 20.8808 16.2283 18.4265 15.009 15.3959C13.7899 12.3652 12.7045 9.73954 12.5972 9.56093C12.3319 9.11945 11.9311 9.1522 11.7132 9.63307Z"
      fill="#FF5849"
      stroke="#FF5849"
      stroke-width="0.781001"
    />
  </svg>
</template>

<script lang="ts" setup></script>
