<template>
  <div class="flex h-full overflow-y-auto">
    <dashboard-sidebar />
    <div class="wrapper">
      <dashboard-header>
        <!-- <ui-plus-button
          class="mr-2"
        >
          Question
        </ui-plus-button> -->
        <span id="add-template">
          <ui-plus-button
            v-if="$route.name !== ROUTER_NAMES.EVALUATOR_QUESTIONNAIRES"
            @click="
              $router.push({
                name: ROUTER_NAMES.EVALUATOR_QUESTIONNAIRES,
                params: { createForm: true },
              })
            "
            light
          >
            Template
          </ui-plus-button>
        </span>
        <ui-plus-button
          class="mr-7 ml-4"
          @click="$router.push({ name: ROUTER_NAMES.EVALUATOR_CLIENTS_CREATE })"
          light
        >
          Client
        </ui-plus-button>
      </dashboard-header>
      <div class="main">
        <slot />
        <p class="text-center text-ghost font-medium text-sm mt-28">
          &copy; Copyright {{ currentYear }} Abilene Advisors
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DashboardHeader from "@/components/common/DashboardHeader";
import DashboardSidebar from "@/components/common/DashboardSidebarEvaluator.vue";
import { ROUTER_NAMES } from "@/router/router.enum";
import UiPlusButton from "@/components/ui/UiPlusButton";
import useProductFruits from "@/core/hooks/useProductFruits";

import clientsApi from "@/services/api/clients-api";

export default defineComponent({
  components: {
    DashboardSidebar,
    DashboardHeader,
    UiPlusButton,
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },

    profile() {
      return this.$store.state.user.profile;
    },
  },

  async mounted() {
    const productfruitData = await clientsApi.fetchProductfruitData();
    useProductFruits(this.profile, productfruitData);
  },

  setup() {
    return {
      ROUTER_NAMES,
    };
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  @apply w-full flex flex-col;
}

.main {
  @apply flex-1;
  @apply overflow-x-visible;
  @apply p-8;
  background: linear-gradient(157.79deg, #f4f7fb 12.7%, #deeaf8 91.05%);
}
</style>

<style>
#app {
  @apply flex flex-col h-full w-full;
}
</style>
