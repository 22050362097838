<template>
  <div class="flex flex-col direction-column h-full overflow-y-auto">
    <header class="w-full pt-5 pb-5 bg-dark-700 h-auto">
      <img
        class="h-15 mx-auto"
        src="@/assets/images/logos/logo_with_text.png"
        alt="Supplier shield"
      />
    </header>
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>
