import store from "../../store";
import { useToast } from "vue-toastification";

interface Params {
  error: any;
  cta: string;
  showToast: boolean;
}

export default (params: Params): void => {
  const toast = useToast();

  if (params.showToast) {
    if (typeof params?.error === "object") {
      const key = Object.keys(params.error)[0];
      const errorText = Array.isArray(params.error[key])
        ? params.error[key][0]
        : params.error[key];
      toast.error(errorText);
    } else {
      toast.error(params.error);
    }
  }

  if (params?.cta) {
    store.commit("SET", {
      key: "clientSubscriptionsModal",
      value: { show: true, title: params.cta },
    });
  }
};
