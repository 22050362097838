import http from "@/services/http";
import { ISocialsList } from "@/core/types/social-auth";

const fetchUser = () => http.get(`${process.env.VUE_APP_API_URL}users/me/get/`);
const resetPassword = (email: string) =>
  http.postWithoutCredentials(
    `${process.env.VUE_APP_API_URL}users/reset_password/request/`,
    {
      email,
    }
  );
const completeResetPassword = (data: { [key: string]: string }) =>
  http.postWithoutCredentials(
    `${process.env.VUE_APP_API_URL}users/reset_password/complete/`,
    data
  );

const completeRegistration = (data: { [key: string]: string }) =>
  http.postWithoutCredentials(
    `${process.env.VUE_APP_API_URL}users/complete_registration/`,
    data
  );

const getSocialConnections = (): Promise<ISocialsList> =>
  http.get(`${process.env.VUE_APP_API_URL}users/social/`);

const removeSocialConnection = (id: number): Promise<void> =>
  http.delete(`${process.env.VUE_APP_API_URL}users/social/${id}/`);

export default {
  fetchUser,
  resetPassword,
  completeResetPassword,
  completeRegistration,
  getSocialConnections,
  removeSocialConnection,
};
