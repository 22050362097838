<template>
  <div
    class="rounded-full border-blue overflow-hidden flex items-center justify-center flex-shrink-0"
    :class="[classObject, lg ? 'border-2' : 'border', tierClass]"
  >
    <span v-if="logoUrl === null" class="block font-semibold text-white">
      {{ companyAbbreviation }}
    </span>

    <img class="w-full h-full" v-else :src="logoUrl" alt="logo" />

    <slot></slot>

    <div v-if="badge && subscription.plan.badge" class="badge">
      {{ subscription.plan.badge }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import useTierClass from "@/core/hooks/subscriptions/useTierClass";

export default {
  props: {
    logoUrl: [String, null],
    name: String,
    id: Number,
    lg: Boolean,
    badge: Boolean,
    setTierClass: Boolean,
  },

  setup(props) {
    const store = useStore();

    const colorClass = [
      "bg-gradient-logo1",
      "bg-gradient-logo2",
      "bg-gradient-logo3",
      "bg-gradient-logo4",
      "bg-gradient-logo5",
    ];

    const subscription = computed(() => store.state.subscription.subscription);
    const classObject = computed(() => {
      const color = {};
      color[colorClass[props.id % colorClass.length]] = true;
      return color;
    });
    const tierClass = computed(() => {
      let classes = null;
      if (props.setTierClass) {
        classes = useTierClass(subscription.value?.plan);
      }
      return classes;
    });

    const companyAbbreviation = computed(() => {
      let words = props.name.split(" ");

      if (words.length <= 1) return ("" + words[0].slice(0, 1)).toUpperCase();

      return ("" + words[0][0] + words[1][0]).toUpperCase();
    });

    return {
      classObject,
      tierClass,
      companyAbbreviation,
      subscription,
    };
  },
};
</script>

<style lang="scss" scoped>
.trial,
.limited {
  @apply border-red-darker;
}

.basic {
  @apply border-blue;
}
.popular {
  @apply border-blue-650;
}
.green {
  @apply border-green-500;
}
.orange {
  @apply border-red;
}
.red {
  @apply border-red-darker;
}
.blue {
  @apply border-blue-650;
}
.blue-dark {
  @apply border-blue-700;
}
.gray {
  @apply border-gray-600;
}
.gray-dark {
  @apply border-dark-700;
}

.badge {
  @apply absolute -bottom-1 h-3 rounded flex items-center text-4xs leading-3 text-white font-semibold bg-gray-600 p-1;

  .basic & {
    @apply bg-blue;
  }
  .popular & {
    @apply bg-blue-650;
  }
  .green & {
    @apply bg-green-500;
  }
  .orange & {
    @apply bg-red;
  }
  .red & {
    @apply bg-red-darker;
  }
  .blue & {
    @apply bg-blue-650;
  }
  .blue-dark & {
    @apply bg-blue-700;
  }
  .gray & {
    @apply bg-gray-600;
  }
  .gray-dark & {
    @apply bg-dark-700;
  }
}
</style>
