
import { defineComponent } from "vue";
import DashboardSidebar from "@/components/common/DashboardSidebarSupplier.vue";

export default defineComponent({
  components: {
    DashboardSidebar,
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  }
});
