import { Commit } from "vuex";

import userApi from "@/services/api/users-api";
import { UserType } from "@/core/user-type";
import { ClientProfileNewType } from "@/modules/client/models/profile/client-profile-new.type";
import clientsApi from "@/services/api/clients-api";
import suppliersApi from "@/services/api/suppliers-api";

type UserState = {
  profile: UserType | null;
  clientProfile: ClientProfileNewType | null;
  profileDeleteModal: boolean;
  contactUsModal: {
    show: boolean;
    apiParams: string | null;
  };
  contactUsModalSuccess: boolean;
};

export enum UserMutations {
  SET_USER = "SET_USER",
  CLEAR_USER = "CLEAR_USER",
  SET_CLIENT_PROFILE = "SET_CLIENT_PROFILE",
  CLEAR_CLIENT_PROFILE = "CLEAR_CLIENT_PROFILE",
}

const state: UserState = {
  profile: null,
  clientProfile: null,
  profileDeleteModal: false,
  contactUsModal: {
    show: false,
    apiParams: "defaultTest",
  },
  contactUsModalSuccess: false,
};

const getters = {
  profile(state: UserState): UserType | null {
    return state.profile;
  },
  clientProfile(state: UserState): ClientProfileNewType | null {
    return state.clientProfile;
  },
};

const actions = {
  async fetchUser(
    { commit, state }: { commit: Commit; state: UserState },
    { force = false }: { force?: boolean } = {}
  ): Promise<UserType | null> {
    try {
      if (state.profile && !force) return state.profile;
      const user = await userApi.fetchUser();
      commit(UserMutations.SET_USER, user);
      return user as UserType;
    } catch (err) {
      return null;
    }
  },

  // eslint-disable-next-line prettier/prettier
  async fetchClientProfile({
    commit,
  }: {
    commit: Commit;
  }): Promise<ClientProfileNewType | null> {
    try {
      const clientProfile = await clientsApi.fetchProfile();
      commit(UserMutations.SET_CLIENT_PROFILE, clientProfile);
      return clientProfile as ClientProfileNewType;
    } catch (e) {
      return null;
    }
  },

  async supplierAssessmentOpened(): Promise<void> {
    try {
      await suppliersApi.supplierAssessmentOpened();
    } catch (e) {
      console.error(e);
    }
  },
};

const mutations = {
  [UserMutations.SET_USER](state: UserState, user: UserType): void {
    state.profile = user;
  },
  [UserMutations.CLEAR_USER](state: UserState): void {
    state.profile = null;
  },
  [UserMutations.SET_CLIENT_PROFILE](
    state: UserState,
    clientProfile: ClientProfileNewType
  ): void {
    state.clientProfile = clientProfile;
  },
  [UserMutations.CLEAR_CLIENT_PROFILE](state: UserState): void {
    state.clientProfile = null;
  },
  SET(state: UserState, { key, value }: { key: string; value: any }): void {
    state[key as keyof UserState] = value;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
