<template>
  <div class="relative">
    <media-provider @fileLoaded="onFileLoaded">
      <template #default="{ tryToLoadFile }">
        <div v-if="!logoUrl" class="w-full rounded-8 bg-gray-100 p-1 h-14">
          <div
            class="border-dashed h-12 border border-gray-500 rounded-6 flex items-center justify-center"
          >
            <img src="@/assets/images/icons/plus_blue.svg" alt="+" />
            <div class="flex flex-col ml-5">
              <h5 class="text-xs font-semibold leading-5">
                {{ title || "Upload supplier logo" }}
              </h5>
              <span class="text-2xs leading-3">
                JPG, PNG format. max 10 mb
              </span>
            </div>
          </div>
          <input
            @change="selectFile"
            accept=".png, .jpg, .jpeg"
            class="absolute block top-0 left-0 right-0 bottom-0 opacity-0"
            type="file"
            name="logo"
            ref="currentFile"
            :disabled="isOpenCropper"
          />
        </div>
        <div class="w-full rounded-8 h-14 flex items-center" v-else>
          <ui-logo class="w-14 h-14" :id="1" name="new" :logoUrl="logoUrl" />
          <div class="ml-6 flex flex-col">
            <h5 class="text-xs font-semibold leading-5">{{ fileName }}</h5>
            <span class="text-2xs leading-3">JPG, PNG format. max 10 mb</span>
          </div>
          <button
            @click="clearFile"
            class="text-2xs leading-5 font-medium cursor-pointer relative flex items-center rounded transition p-2 text-red-darker hover:bg-gray-100 ml-auto"
          >
            <img
              class="h-4 w-4"
              src="@/assets/images/icons/delete_red.svg"
              alt="delete icon"
            />
            Delete
          </button>
        </div>

        <ui-cropper
          v-if="isOpenCropper"
          :src="fileUrl"
          :savingCallback="tryToLoadFile"
          :title="title || 'Upload supplier logo'"
          :above="above"
          @save="save"
          @close="close()"
          round
        />
      </template>
    </media-provider>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import UiCropper from "./UiCropper.vue";
import MediaProvider from "@/components/renderless/MediaProvider.vue";
import UiLogo from "./UiLogo.vue";

export default {
  props: {
    logoFile: [Object, null],
    above: Boolean,
    title: String,
  },
  components: {
    UiCropper,
    MediaProvider,
    UiLogo,
  },
  setup(props, { emit }) {
    const isOpenCropper = ref(false);
    const localUrlLogo = ref(null);
    const fileUrl = ref("");
    const currentFile = ref(null);
    const fileName = ref("");

    function selectFile(event) {
      const file = currentFile.value.files[0];
      fileName.value = file.name;

      if (file) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener("load", function () {
          fileUrl.value = this.result;
          isOpenCropper.value = true;
        });
      }
    }

    const logoUrl = computed(() => {
      if (localUrlLogo.value) return localUrlLogo.value;
      if (props.logoFile) return props.logoFile.file;
      return null;
    });

    function close() {
      isOpenCropper.value = false;
      currentFile.value.value = null;
    }

    function save(newImgUrl) {
      localUrlLogo.value = newImgUrl;
    }

    function clearFile() {
      emit("loaded-file", null);
      localUrlLogo.value = null;
    }

    async function onFileLoaded(fileInfo) {
      emit("loaded-file", fileInfo);
    }

    return {
      isOpenCropper,

      selectFile,
      currentFile,

      fileUrl,
      logoUrl,

      close,
      save,
      onFileLoaded,
      fileName,
      clearFile,
    };
  },
};
</script>
