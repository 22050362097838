import { SubscriptionTierType } from '../types/subscription';
import { SubsriptionTierEnum } from './../enums/subsctiption';

export const SUBSCRIPTION_NAMES: Record<SubscriptionTierType, string> = {
  [SubsriptionTierEnum.TIER_0]: "Tier 0",
  [SubsriptionTierEnum.TIER_1]: "Tier 1",
  [SubsriptionTierEnum.TIER_2]: "Tier 2",
  [SubsriptionTierEnum.TIER_3]: "Tier 3",
}

export const SUBSCRIPTION_API_VALUES_BY_NAME: Record<string, SubscriptionTierType> = {
  ["Tier 0"]: SubsriptionTierEnum.TIER_0,
  ["Tier 1"]: SubsriptionTierEnum.TIER_1,
  ["Tier 2"]: SubsriptionTierEnum.TIER_2,
  ["Tier 3"]: SubsriptionTierEnum.TIER_3,
}

