<template>
  <div class="field-wrapper" :style="{ height: `${height}px` }">
    <div
      class="field"
      :class="{
        'field--with-error': !!errorMessage || errorFromApi,
        'field--active':
          onFocus ||
          (inputValue && inputValue.length) ||
          (modelValue && modelValue.length),
        'field--outlined': outlined,
        'field--bordered': bordered,
        'field--rounded': rounded,
        'field--height-52': height.toString() === '52',
      }"
    >
      <textarea
        v-if="text && !withoutValidation"
        :name="name"
        :value="inputValue"
        class="field--textarea"
        :placeholder="placeholder"
        :class="{ 'mb-1': !label }"
        :disabled="disabled"
        :maxlength="max"
        @focus="focusHandler"
        @blur="blurHandler"
        @input="onValidationInput"
        @keypress="beforeChangeHandler"
      />
      <textarea
        v-if="text && withoutValidation"
        :name="name"
        :value="modelValue"
        class="field--textarea"
        :placeholder="placeholder"
        :class="{ 'mb-1': !label }"
        :disabled="disabled"
        :maxlength="max"
        @focus="focusHandler"
        @blur="blurHandler"
        @input="onInput"
        @keypress="beforeChangeHandler"
      />
      <input
        v-else-if="!text && !withoutValidation"
        :ref="name"
        :value="inputValue"
        class="field--input"
        :class="{ 'mb-1': !label }"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="inputType"
        :maxlength="max"
        @input="onValidationInput"
        @focus="focusHandler"
        @blur="blurHandler"
        @keypress="beforeChangeHandler"
      />
      <input
        v-else-if="!text && withoutValidation"
        :ref="name"
        :value="modelValue"
        class="field--input"
        :class="{ 'mb-1.5': !label }"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="inputType"
        :maxlength="max"
        @input="onInput"
        @focus="focusHandler"
        @blur="blurHandler"
        @keypress="beforeChangeHandler"
      />
      <label v-if="label" class="field--label" :for="name">{{ label }}</label>
      <span v-if="type === 'password'" class="field--after" @click="togglePass">
        <img v-if="!showPass" src="@/assets/images/icons/eye.svg" alt="eye" />
        <img
          v-if="showPass"
          src="@/assets/images/icons/eye_close.svg"
          alt="eye"
        />
      </span>
      <div class="field--icon" v-if="slots.icon">
        <slot name="icon" />
      </div>
    </div>
    <p v-if="errorMessage || errorFromApi" class="field--error__text">
      {{ errorMessage || errorFromApi }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { ref, computed } from "vue";

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    successMessage: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: "44",
      validate: (value) =>
        ["36", "44", "52", "80", "116", "140"].includes(value.toString()),
    },
    placeholder: {
      type: String,
      default: "",
    },
    text: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    withoutValidation: {
      type: Boolean,
      default: false,
    },
    apiErrors: {
      type: Object,
      default: () => ({}),
    },
    max: {
      type: [String, Number],
      default: Infinity,
    },
    onlyNumber: {
      type: Boolean,
      default: false,
    },
    ignoreEnterKey: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["blur", "update:modelValue", "enter-pressed", "focus"],
  setup(props, { emit, slots }) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const onFocus = ref(false);
    const showPass = ref(false);
    let inputType = ref(props.type);

    const errorFromApi = computed(() => {
      if (props.name && props.apiErrors && props.apiErrors[props.name]) {
        const err = props.apiErrors[props.name];
        return typeof err === "string" ? err : err[0];
      }
      return "";
    });

    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    const focusHandler = () => {
      onFocus.value = true;
      emit("focus");
    };
    const blurHandler = () => {
      emit("blur", { value: inputValue.value, valid: meta.valid });
      onFocus.value = false;
    };

    const togglePass = () => {
      inputType.value === "password"
        ? (inputType.value = "text")
        : (inputType.value = "password");
      showPass.value = !showPass.value;
    };

    const onInput = (ev) => {
      emit("update:modelValue", ev.target.value);
    };

    const onValidationInput = (ev) => {
      handleChange(ev);
      emit("update:modelValue", ev.target.value);
    };

    const beforeChangeHandler = (ev) => {
      const keyCode = ev.keyCode ? ev.keyCode : ev.which;
      if (props.onlyNumber) {
        if ((keyCode < 48 || keyCode > 57) && ![43].includes(keyCode)) {
          // 43 is plus
          ev.preventDefault();
        }
      }
      if (props.ignoreEnterKey) {
        if (keyCode === 13 && !ev.shiftKey) {
          ev.preventDefault();
          emit("enter-pressed");
        }
      }
    };

    return {
      slots,
      onFocus,
      handleChange,
      errorMessage,
      inputValue,
      meta,
      showPass,
      inputType,
      errorFromApi,
      togglePass,
      focusHandler,
      blurHandler,
      onInput,
      onValidationInput,
      beforeChangeHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
.field-wrapper {
  @apply min-w-0;
  @apply relative;
}

.field {
  @apply relative;
  @apply flex flex-col-reverse;
  @apply bg-gray-100;
  @apply rounded-4;
  @apply py-3 px-4;
  @apply border border-gray-100;
  @apply text-sm;
  @apply h-full;

  &--input:disabled + .field--label {
    @apply text-gray-400;
    @apply cursor-default;
  }

  &--input:disabled {
    @apply text-gray-400;
  }

  &--icon {
    @apply absolute right-4 top-1/2;
    @apply transform -translate-y-1/2;
  }

  &--rounded {
    @apply rounded;
  }

  &--readonly {
    @apply pointer-events-none;
    @apply text-gray-600;

    &.field--input,
    &.field--label {
      @apply pointer-events-none;
    }
  }

  &--outlined {
    @apply bg-white;
  }

  &--bordered {
    @apply border border-gray-300;
  }

  &--before {
    @apply absolute top-7 left-4;
    @apply hidden;
  }

  &--after {
    @apply absolute top-1/2 right-4;
    @apply transform -translate-y-1/2;
    @apply cursor-pointer;
    @apply z-50;
  }

  &--with-error {
    .field--label {
      @apply text-red;
    }

    &.field--active {
      .field--input + label,
      .field--input:focus + label {
        @apply text-red;
      }
    }
  }

  &--with-prefix {
    .field--input {
      @apply ml-5;
    }
  }

  &--error__text {
    @apply text-red text-2xs;
    @apply absolute left-4 -bottom-0.25;
    @apply transform translate-y-full;
    @apply leading-3;
  }

  &--input {
    @apply bg-transparent;
    @apply outline-none;
    @apply z-10;
    @apply transform translate-y-2;
  }

  &--textarea {
    @apply resize-none;
    @apply outline-none;
    @apply bg-transparent;
    @apply h-full;
    @apply overflow-x-hidden overflow-y-auto;

    &[disabled] {
      @apply cursor-not-allowed text-gray-400;
    }

    + .field--label {
      @apply text-gray-400;
      @apply cursor-default;
    }
  }

  &--label {
    @apply text-sm text-left text-gray;
    @apply transition-all duration-200;
  }

  &--active {
    .field--input:focus + label + .field--before,
    .field--input + label + .field--before {
      @apply flex;
    }

    .field--input + label {
      @apply whitespace-nowrap;
      @apply transform translate-x-0 translate-y-5.5 scale-100 origin-bottom-left;
    }

    .field--input + label,
    .field--input:focus + label {
      @apply text-xs;
      @apply text-gray-400;
      @apply cursor-pointer;
      @apply transform translate-x-0 translate-y-3;
    }
  }
}

.field--input + label {
  @apply whitespace-nowrap;
  @apply transform translate-x-0 translate-y-5.5 scale-100 origin-bottom-left;
}

.field--input:focus::-webkit-input-placeholder {
  @apply opacity-100;
}

.field--input::-webkit-input-placeholder {
  @apply opacity-0;
}

.field--textarea::-webkit-input-placeholder {
  @apply text-gray-700;
}

.field--after-slot {
  @apply absolute top-1 right-1 z-10;
}

.field--height-52 {
  .field--input {
    @apply translate-y-1;
  }

  .field--input + label {
    @apply whitespace-nowrap;
    @apply translate-x-0 translate-y-4.5 scale-100 origin-bottom-left;
  }

  &.field--active {
    .field--input + label {
      @apply whitespace-nowrap;
      @apply translate-x-0 translate-y-5.5 scale-100 origin-bottom-left;
    }

    .field--input + label,
    .field--input:focus + label {
      @apply text-xs;
      @apply text-gray-400;
      @apply translate-x-0 translate-y-1.5;
    }
  }
}
</style>
