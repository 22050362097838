export enum ErrorEnum {
  PERMISSION_ERROR = "You do not have permission to perform this action",
  NEED_MORE_SUPPLIER = "Need more suppliers? Consider upgrade",
  ASSESS_SUPPLIER = "Time to assess your supplier? Consider upgrade",
  NEED_MORE_USERS = "Need more users? Consider upgrade",
  NEED_MORE_ASSESSMENTS = "Need more assessments? Consider upgrade",
  NEED_MORE_INFO = "Need to add more information? Consider upgrade",
  NEED_MORE_FUTURES = "Need more features? Consider upgrade",
  CUSTOMIZE_QUESTIONNAIRE = "Unlock personalized questionnaires, upgrade now",
  EXPORT_PDF = "Want to read PDF report? Consider upgrade",
  EXPAND_TEAM = "Want to expand your team? Consider upgrade",
  RISK_ERROR = "Risk won’t wait, Upgrade Now",
}
