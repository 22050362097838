function sanitizeHtml(html: string): string {
  const sanitized = html.replace(/<[^>]+>/g, "");
  return sanitized.replace(/[^\w\s]/gi, "");
}

function clientSideSanitization(
  el: HTMLElement,
  { modifiers, oldValue, value }: any
): void {
  if (value !== oldValue) {
    el.innerHTML = sanitizeHtml(value);
  }
}

export const directive = {
  inserted: clientSideSanitization,
  mounted: clientSideSanitization,
  update: clientSideSanitization,
  updated: clientSideSanitization,
};

export default {
  install(app: any, { name = "sanitize" } = {}) {
    app.directive(name, directive);
  },
};
