<script>
import ApiCms from "@/services/api/cms-api";
import http from "@/services/http";
import { prepareFormData } from "@/utils/form-data";

export default {
  data: () => ({
    loading: false,
  }),
  emits: ["fileLoaded"],
  methods: {
    async tryToLoadFile(payload, filesCount) {
      try {
        this.loading = true;
        // eslint-disable-next-line camelcase
        const { file, upload_params } = await ApiCms.addMedia(payload.name);
        await http.postWithoutCredentials(
          `${upload_params.url}`,
          prepareFormData({ file: payload, data: upload_params.fields }),
          undefined,
          true
        );
        this.$emit("fileLoaded", file, filesCount);
      } catch (err) {
        console.log(err);
        throw new Error("Error while loading file", err);
      } finally {
        this.loading = false;
      }
    },
  },
  render() {
    return this.$slots.default({
      tryToLoadFile: this.tryToLoadFile,
      loading: this.loading,
    });
  },
};
</script>
