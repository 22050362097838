export enum AssessmentStatusEnum {
  ON_SUPPLIER = "ON_SUPPLIER",
  DRAFT = "DRAFT",
  ON_EVALUATOR = "ON_EVALUATOR",
  OVERDUE = "OVERDUE",
  COMPLETED = "COMPLETED",
}

export enum AssessmentTypeEnum {
  SELF_MADE = "Me",
  SUPPLIER_MADE = "The supplier",
}

export enum EvaluationTypeEnum {
  SELF_EVALUATION = "Me",
  PRO_EVALUATION = "My expert team",
}
