<template>
  <svg
    width="156"
    height="156"
    viewBox="0 0 156 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="156" height="156" fill="white" />
    <path
      d="M78.4776 125.881C106.416 125.881 129.067 103.231 129.067 75.1926C129.067 47.1541 106.317 24.5039 78.4776 24.5039C50.539 24.5039 27.8887 47.1541 27.8887 75.1926C27.8887 103.231 50.539 125.881 78.4776 125.881Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
    />
    <path
      d="M108.288 24.5039C97.682 45.4091 82.1138 53.7256 67.8449 58.692C64.5334 59.8606 60.9296 60.883 57.5207 60.0066C54.1117 59.1302 51.141 55.6732 52.0663 52.2649C52.8942 49.1001 56.985 47.542 59.9556 48.8079C62.975 50.0739 64.6308 53.5795 64.3386 56.8418C64.0464 60.104 62.0497 62.9767 59.5173 64.973C56.9363 66.9693 53.8682 68.1865 50.7515 69.1116C43.8848 71.1566 36.726 72.1304 30.0542 74.711C23.3337 77.2916 16.9541 81.9171 14.6653 88.685C13.0095 93.5053 13.6913 98.9586 15.834 103.535C17.9768 108.112 21.6293 111.959 25.72 115.026C32.0022 119.701 39.7454 122.622 47.5373 122.427C55.3292 122.232 63.2185 118.824 68.0397 112.689C72.8609 106.554 74.2732 97.644 70.8643 90.5839C68.5267 85.7149 63.2185 81.7223 57.9103 82.8909C53.7221 83.8647 50.6541 87.9547 50.2158 92.2881C49.7775 96.6215 51.6767 100.906 54.5013 104.12C57.3259 107.382 61.0757 109.67 64.9717 111.667C70.6695 114.539 77.39 116.73 83.2826 114.296C88.883 112.007 92.1459 106.213 94.2886 100.614C96.4314 95.0147 98.0385 88.9285 101.934 84.3029C106.61 78.7523 114.499 76.1717 121.56 77.8271C128.622 79.4826 134.563 85.3254 136.316 92.3368C137.68 97.79 136.024 104.607 130.911 106.992C126.917 108.891 121.999 107.528 118.541 104.801C115.083 102.075 112.746 98.1795 110.506 94.3817"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-dasharray="4.08 4.08"
    />
    <path
      d="M69.0404 47.8077C77.5666 50.694 86.8182 46.122 89.7045 37.5959C92.5908 29.0698 88.0188 19.8182 79.4926 16.9319C70.9665 14.0456 61.7149 18.6176 58.8286 27.1437C55.9423 35.6698 60.5143 44.9214 69.0404 47.8077Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M70.0193 44.9149C76.9478 47.2604 84.4658 43.5451 86.8112 36.6166C89.1567 29.6881 85.4414 22.1701 78.5129 19.8247C71.5844 17.4792 64.0664 21.1945 61.721 28.123C59.3755 35.0515 63.0908 42.5695 70.0193 44.9149Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M73.714 34.006C74.6177 34.3119 75.5983 33.8273 75.9043 32.9236C76.2102 32.0199 75.7256 31.0393 74.8219 30.7334C73.9182 30.4274 72.9375 30.912 72.6316 31.8158C72.3257 32.7195 72.8103 33.7001 73.714 34.006Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M77.0352 24.1882L74.6348 31.2789"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M76.0897 32.3782L80.2771 32.5799"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M78.1234 22.698C78.3893 22.5712 78.4894 22.2402 78.3469 21.9586C78.2044 21.677 77.8734 21.5514 77.6074 21.6782C77.3415 21.805 77.2415 22.136 77.384 22.4176C77.5264 22.6992 77.8575 22.8248 78.1234 22.698Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M71.2269 43.044C71.4928 42.9172 71.5929 42.5861 71.4504 42.3045C71.3079 42.0229 70.9769 41.8974 70.711 42.0241C70.4451 42.1509 70.345 42.482 70.4875 42.7636C70.6299 43.0452 70.961 43.1707 71.2269 43.044Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M64.4925 29.447C64.7584 29.3203 64.8585 28.9892 64.716 28.7076C64.5735 28.426 64.2425 28.3004 63.9766 28.4272C63.7107 28.554 63.6106 28.885 63.7531 29.1667C63.8956 29.4483 64.2266 29.5738 64.4925 29.447Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M84.8422 36.3443C85.1081 36.2175 85.2081 35.8864 85.0656 35.6048C84.9232 35.3232 84.5921 35.1977 84.3262 35.3244C84.0603 35.4512 83.9602 35.7823 84.1027 36.0639C84.2452 36.3455 84.5762 36.471 84.8422 36.3443Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M25.2809 52.6315L27.9774 51.8188C28.1727 51.7621 28.1727 51.4785 27.9774 51.4218L25.2809 50.6091C25.2115 50.5902 25.1611 50.5398 25.1422 50.4705L24.3295 47.7803C24.2728 47.585 23.9893 47.585 23.9326 47.7803L23.1198 50.4705C23.1009 50.5398 23.0505 50.5902 22.9812 50.6091L20.291 51.4218C20.0957 51.4785 20.0957 51.7621 20.291 51.8188L22.9875 52.6315C23.0568 52.6504 23.1072 52.7008 23.1261 52.7701L23.9389 55.4666C23.9956 55.6619 24.2791 55.6619 24.3358 55.4666L25.1485 52.7701C25.1611 52.7008 25.2115 52.6504 25.2809 52.6315Z"
      fill="#86A6CB"
    />
    <path
      d="M111.273 21.0681L114.031 21.64C114.229 21.6833 114.365 21.4341 114.22 21.2911L112.238 19.2906C112.186 19.2409 112.166 19.1726 112.182 19.1026L112.751 16.3505C112.794 16.1518 112.545 16.0166 112.402 16.1612L110.405 18.138C110.355 18.1899 110.287 18.2101 110.217 18.1937L107.464 17.6248C107.266 17.5815 107.131 17.8307 107.275 17.9737L109.257 19.9742C109.309 20.0239 109.33 20.0922 109.313 20.1621L108.741 22.9198C108.698 23.1185 108.947 23.2537 109.09 23.1091L111.091 21.1268C111.135 21.0719 111.203 21.0516 111.273 21.0681Z"
      fill="#86A6CB"
    />
    <path
      d="M109.576 89.2877L111.683 87.4188C111.837 87.2854 111.718 87.0281 111.517 87.0585L108.728 87.4519C108.657 87.4638 108.59 87.4392 108.544 87.3842L106.678 85.2832C106.544 85.1297 106.287 85.2486 106.317 85.4497L106.708 88.2326C106.72 88.3034 106.695 88.3703 106.64 88.4166L104.539 90.2828C104.386 90.4162 104.505 90.6735 104.706 90.6431L107.495 90.2497C107.565 90.2378 107.632 90.2624 107.678 90.3174L109.547 92.4241C109.681 92.5776 109.938 92.4587 109.908 92.2576L109.514 89.469C109.497 89.4008 109.521 89.3339 109.576 89.2877Z"
      fill="#86A6CB"
    />
    <path
      d="M88.1222 70.215L90.4817 69.5039C90.6526 69.4543 90.6526 69.2063 90.4817 69.1566L88.1222 68.4455C88.0616 68.429 88.0175 68.3849 88.0009 68.3243L87.2898 65.9704C87.2402 65.7996 86.9921 65.7996 86.9425 65.9704L86.2313 68.3243C86.2148 68.3849 86.1707 68.429 86.1101 68.4455L83.7561 69.1566C83.5852 69.2063 83.5852 69.4543 83.7561 69.5039L86.1156 70.215C86.1762 70.2316 86.2203 70.2757 86.2368 70.3363L86.948 72.6956C86.9976 72.8665 87.2457 72.8665 87.2953 72.6956L88.0064 70.3363C88.0175 70.2757 88.0616 70.2316 88.1222 70.215Z"
      fill="#86A6CB"
    />
    <path
      d="M42.8449 103.32L45.2043 102.507C45.3752 102.451 45.3752 102.167 45.2043 102.111L42.8449 101.298C42.7842 101.279 42.7401 101.229 42.7236 101.159L42.0124 98.469C41.9628 98.2737 41.7147 98.2737 41.6651 98.469L40.954 101.159C40.9374 101.229 40.8933 101.279 40.8327 101.298L38.4788 102.111C38.3079 102.167 38.3079 102.451 38.4788 102.507L40.8382 103.32C40.8989 103.339 40.943 103.39 40.9595 103.459L41.6706 106.155C41.7203 106.351 41.9683 106.351 42.018 106.155L42.7291 103.459C42.7401 103.39 42.7842 103.339 42.8449 103.32Z"
      fill="#86A6CB"
    />
    <path
      d="M38.9456 63.4234L40.6309 62.9154C40.753 62.88 40.753 62.7028 40.6309 62.6674L38.9456 62.1595C38.9022 62.1477 38.8707 62.1162 38.8589 62.0728L38.351 60.3916C38.3155 60.2695 38.1383 60.2695 38.1029 60.3916L37.5949 62.0728C37.5831 62.1162 37.5516 62.1477 37.5083 62.1595L35.8269 62.6674C35.7048 62.7028 35.7048 62.88 35.8269 62.9154L37.5122 63.4234C37.5555 63.4352 37.587 63.4667 37.5989 63.51L38.1068 65.1952C38.1423 65.3172 38.3195 65.3172 38.3549 65.1952L38.8629 63.51C38.8707 63.4667 38.9022 63.4352 38.9456 63.4234Z"
      fill="#86A6CB"
    />
    <path
      d="M136.047 64.5364L137.732 64.0285C137.855 63.993 137.855 63.8159 137.732 63.7804L136.047 63.2725C136.004 63.2607 135.972 63.2292 135.96 63.1859L135.453 61.5046C135.417 61.3826 135.24 61.3826 135.204 61.5046L134.696 63.1859C134.685 63.2292 134.653 63.2607 134.61 63.2725L132.928 63.7804C132.806 63.8159 132.806 63.993 132.928 64.0285L134.614 64.5364C134.657 64.5482 134.689 64.5797 134.7 64.623L135.208 66.3082C135.244 66.4303 135.421 66.4303 135.456 66.3082L135.964 64.623C135.972 64.5797 136.004 64.5482 136.047 64.5364Z"
      fill="#86A6CB"
    />
    <path
      d="M51.4055 39.9304L53.0908 39.4225C53.2129 39.3871 53.2129 39.2099 53.0908 39.1745L51.4055 38.6666C51.3622 38.6547 51.3307 38.6232 51.3189 38.5799L50.8109 36.8987C50.7755 36.7766 50.5983 36.7766 50.5628 36.8987L50.0549 38.5799C50.0431 38.6232 50.0116 38.6547 49.9683 38.6666L48.2869 39.1745C48.1648 39.2099 48.1648 39.3871 48.2869 39.4225L49.9722 39.9304C50.0155 39.9423 50.047 39.9738 50.0588 40.0171L50.5668 41.7023C50.6022 41.8243 50.7794 41.8243 50.8149 41.7023L51.3228 40.0171C51.3307 39.9738 51.3622 39.9423 51.4055 39.9304Z"
      fill="#86A6CB"
    />
    <path
      d="M26.303 96.5244L27.9883 96.0165C28.1104 95.9811 28.1104 95.8039 27.9883 95.7685L26.303 95.2605C26.2597 95.2487 26.2282 95.2172 26.2163 95.1739L25.7084 93.4927C25.6729 93.3706 25.4957 93.3706 25.4603 93.4927L24.9523 95.1739C24.9405 95.2172 24.909 95.2487 24.8657 95.2605L23.1843 95.7685C23.0623 95.8039 23.0623 95.9811 23.1843 96.0165L24.8697 96.5244C24.913 96.5363 24.9445 96.5678 24.9563 96.6111L25.4642 98.2963C25.4997 98.4183 25.6769 98.4183 25.7123 98.2963L26.2203 96.6111C26.2282 96.5678 26.2597 96.5363 26.303 96.5244Z"
      fill="#86A6CB"
    />
    <path
      d="M72.627 70.4854L75.9816 75.2674C76.9401 76.6336 77.5956 77.7756 78.0952 79.3181C79.9656 84.2676 79.2679 89.7081 76.6942 94.1347C76.2164 94.9067 75.9732 95.8054 76.0911 96.5964C76.1116 97.4557 76.3665 98.4419 76.9142 99.2226C78.0095 100.784 79.8463 101.534 81.6918 101.259C87.6187 100.159 93.7146 101.999 97.8893 106.497C98.3977 107.014 98.8085 107.599 99.2877 108.282L102.642 113.064C103.053 113.65 103.366 114.304 103.709 114.792L75.0166 134.92C74.5767 134.5 74.0975 133.817 73.7552 133.329L70.4006 128.547C70.0583 128.059 69.8136 127.503 69.4713 127.015C69.3343 126.819 69.2265 126.458 69.0212 126.165C66.3788 120.738 66.6948 114.546 69.7172 109.514C70.6727 107.97 70.5241 105.89 69.4287 104.328C68.9495 103.645 68.2066 103.001 67.4055 102.689C67.337 102.592 67.2394 102.66 67.2394 102.66C66.4383 102.349 65.5789 102.369 64.7195 102.389C60.7357 103.145 56.6529 102.515 52.9691 100.585C52.031 100.078 51.093 99.571 50.2816 98.8296C50.0471 98.7029 49.8417 98.4101 49.7048 98.215C48.825 97.376 47.8768 96.4394 47.1237 95.3659L43.7691 90.5839C43.7006 90.4863 43.5637 90.2911 43.4268 90.0959L72.3139 69.8314C72.4216 70.1926 72.4901 70.2902 72.627 70.4854Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.01893"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M88.7935 116.37C88.7935 116.37 78.4706 125.941 77.7773 125.991C77.3185 126.167 73.2575 127.851 70.6706 128.937L69.986 127.961C69.6437 127.474 69.3014 126.986 68.9882 126.332C69.1149 126.097 69.1732 125.765 69.2314 125.433C70.5554 121.3 74.6702 114.919 83.3429 115.242L88.7935 116.37Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.01893"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M72.627 70.4854L75.9816 75.2674C76.9401 76.6336 77.5956 77.7756 78.0952 79.3181C79.9656 84.2676 79.2679 89.7081 76.6942 94.1347C76.2164 94.9067 75.9732 95.8054 76.0911 96.5964C76.1116 97.4557 76.3665 98.4419 76.9142 99.2226C78.0095 100.784 79.8463 101.534 81.6918 101.259C87.6187 100.159 93.7146 101.999 97.8893 106.497C98.3977 107.014 98.8085 107.599 99.2877 108.282L102.642 113.064C103.053 113.65 103.366 114.304 103.709 114.792L75.0166 134.92C74.5767 134.5 74.0975 133.817 73.7552 133.329L70.4006 128.547C70.0583 128.059 69.8136 127.503 69.4713 127.015C69.3343 126.819 69.2265 126.458 69.0212 126.165C66.3788 120.738 66.6948 114.546 69.7172 109.514C70.6727 107.97 70.5241 105.89 69.4287 104.328C68.9495 103.645 68.2066 103.001 67.4055 102.689C67.337 102.592 67.2394 102.66 67.2394 102.66C66.4383 102.349 65.5789 102.369 64.7195 102.389C60.7357 103.145 56.6529 102.515 52.9691 100.585C52.031 100.078 51.093 99.571 50.2816 98.8296C50.0471 98.7029 49.8417 98.4101 49.7048 98.215C48.825 97.376 47.8768 96.4394 47.1237 95.3659L43.7691 90.5839C43.7006 90.4863 43.5637 90.2911 43.4268 90.0959L72.3139 69.8314C72.4216 70.1926 72.4901 70.2902 72.627 70.4854Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
    />
    <path
      d="M70.4173 90.3811L59.0195 99.2504L53.1319 100.614C52.1938 100.107 51.2558 99.6001 50.4444 98.8587C50.2099 98.732 50.0045 98.4392 49.8676 98.244C53.0167 92.9769 55.9139 89.634 64.3403 87.9457C65.7561 87.6806 67.0743 87.4839 68.6562 87.2479C82.7372 85.5246 70.4173 90.3811 70.4173 90.3811Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.01893"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M78.1622 79.4159C80.0326 84.3654 79.3349 89.8058 76.7611 94.2324C76.2834 95.0045 76.0401 95.9032 76.1581 96.6941L76.0605 96.7626C74.4991 97.858 74.0913 100.183 75.1866 101.744L85.3874 116.285L82.5573 118.271L72.288 103.632C71.1926 102.07 68.9655 101.594 67.3064 102.758C66.5053 102.446 65.6459 102.467 64.7865 102.487C60.8027 103.243 56.7198 102.613 53.0361 100.683C52.098 100.176 51.16 99.6688 50.3486 98.9274C59.0387 97.1998 61.9635 92.2356 64.2153 88.1804C64.9465 86.9394 65.5802 85.7668 66.2822 84.6918C69.0614 80.558 73.1617 79.1377 78.1622 79.4159Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M99.1888 108.351L102.543 113.133C102.954 113.718 103.267 114.372 103.61 114.86L78.0406 132.797L73.3431 132.743L70.3308 128.449C69.9885 127.961 69.6462 127.473 69.4015 126.917C69.2645 126.722 69.1567 126.361 68.9514 126.068C69.049 125.999 69.1174 126.097 69.215 126.029C69.3811 126.058 69.4786 125.989 69.5762 125.921C79.0864 123.909 81.6295 118.193 84.0372 113.738C84.4174 113.034 84.8952 112.262 85.3438 111.656C88.289 107.551 92.5554 106.16 97.7219 106.467C98.2012 107.151 98.7095 107.668 99.1888 108.351Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M74.967 70.297L42.957 92.7524C42.2739 93.2316 41.2776 93.0568 40.8668 92.4713L38.676 89.3483C38.1968 88.6652 38.44 87.7665 39.1232 87.2872L71.2308 64.7635C71.9139 64.2842 72.8418 64.3614 73.321 65.0446L75.4433 68.0699C75.7274 68.8899 75.6502 69.8178 74.967 70.297Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M107.279 116.361L75.2694 138.816C74.5863 139.295 73.6584 139.218 73.1792 138.535L71.0569 135.509C70.5777 134.826 70.8209 133.928 71.504 133.448L103.612 110.925C104.295 110.445 105.223 110.523 105.702 111.206L107.824 114.231C108.108 115.051 107.963 115.881 107.279 116.361Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M126.329 48.0454L124.867 50.7651C124.449 51.5421 124.065 52.1228 123.455 52.7249C121.561 54.7833 118.826 55.7452 116.124 55.5803C115.646 55.5379 115.164 55.6364 114.818 55.8798C114.417 56.0935 114.012 56.448 113.773 56.892C113.296 57.78 113.377 58.8257 113.945 59.632C115.872 62.1704 116.45 65.4864 115.316 68.526C115.193 68.8889 115.014 69.2219 114.805 69.6104L113.342 72.3301C113.163 72.6631 112.929 72.9663 112.78 73.2438L96.4617 64.4694C96.5554 64.1621 96.7643 63.7735 96.9135 63.496L98.3759 60.7764C98.5252 60.4989 98.7299 60.2512 98.8791 59.9737C98.9388 59.8627 99.0838 59.726 99.1734 59.5595C101.11 57.0231 104.109 55.7025 107.204 55.9355C108.16 56.0203 109.107 55.4563 109.585 54.5683C109.794 54.1798 109.921 53.6761 109.878 53.2237C109.908 53.1682 109.853 53.1383 109.853 53.1383C109.81 52.6859 109.596 52.2848 109.383 51.8838C108.08 50.1815 107.408 48.1033 107.446 45.9052C107.462 45.3418 107.479 44.7784 107.637 44.2192C107.641 44.0784 107.73 43.9118 107.79 43.8008C107.978 43.1861 108.195 42.5159 108.523 41.9054L109.986 39.1857C110.015 39.1302 110.075 39.0192 110.135 38.9082L126.564 47.7422C126.419 47.8789 126.389 47.9344 126.329 48.0454Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.01893"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M108.497 66.5752C108.497 66.5752 101.526 63.9714 101.338 63.6557C101.146 63.4808 99.3862 61.9624 98.2589 60.9984L98.5573 60.4434C98.7066 60.1659 98.8558 59.8884 99.0903 59.5852C99.2312 59.5894 99.4019 59.5381 99.5726 59.4868C101.839 59.1312 105.83 59.56 107.736 63.7331L108.497 66.5752Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.01893"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M126.329 48.0454L124.867 50.7651C124.449 51.5421 124.065 52.1228 123.455 52.7249C121.561 54.7833 118.826 55.7452 116.124 55.5803C115.646 55.5379 115.164 55.6364 114.818 55.8798C114.417 56.0935 114.012 56.448 113.773 56.892C113.296 57.78 113.377 58.8257 113.945 59.632C115.872 62.1704 116.45 65.4864 115.316 68.526C115.193 68.8889 115.014 69.2219 114.805 69.6104L113.342 72.3301C113.163 72.6631 112.929 72.9663 112.78 73.2438L96.4617 64.4694C96.5554 64.1621 96.7643 63.7735 96.9135 63.496L98.3759 60.7764C98.5252 60.4989 98.7299 60.2512 98.8791 59.9737C98.9388 59.8627 99.0838 59.726 99.1734 59.5595C101.11 57.0231 104.109 55.7025 107.204 55.9355C108.16 56.0203 109.107 55.4563 109.585 54.5683C109.794 54.1798 109.921 53.6761 109.878 53.2237C109.908 53.1682 109.853 53.1383 109.853 53.1383C109.81 52.6859 109.596 52.2848 109.383 51.8838C108.08 50.1815 107.408 48.1033 107.446 45.9052C107.462 45.3418 107.479 44.7784 107.637 44.2192C107.641 44.0784 107.73 43.9118 107.79 43.8008C107.978 43.1861 108.195 42.5159 108.523 41.9054L109.986 39.1857C110.015 39.1302 110.075 39.0192 110.135 38.9082L126.564 47.7422C126.419 47.8789 126.389 47.9344 126.329 48.0454Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
    />
    <path
      d="M116.409 51.7261L109.514 48.4479L107.472 45.9907C107.489 45.4273 107.505 44.8639 107.663 44.3046C107.667 44.1638 107.757 43.9973 107.816 43.8863C111.052 44.1234 113.319 44.6983 116.116 48.2776C116.578 48.8834 116.983 49.4594 117.47 50.1506C121.627 56.3924 116.409 51.7261 116.409 51.7261Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.01893"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M123.427 52.7804C121.533 54.8387 118.797 55.8006 116.096 55.6358C115.618 55.5933 115.135 55.6918 114.79 55.9353L114.734 55.9054C113.846 55.4279 112.651 55.7871 112.174 56.6752L107.727 64.9451L106.117 64.0796L110.594 55.7542C111.072 54.8661 110.768 53.7011 109.824 53.1938C109.781 52.7414 109.568 52.3403 109.354 51.9392C108.051 50.237 107.38 48.1588 107.417 45.9607C107.434 45.3973 107.451 44.8339 107.608 44.2746C110.487 47.9692 113.526 48.1723 115.976 48.2733C116.736 48.3241 117.44 48.345 118.115 48.4214C120.727 48.7528 122.371 50.3524 123.427 52.7804Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M114.751 69.5807L113.289 72.3003C113.11 72.6333 112.875 72.9365 112.726 73.214L98.1843 65.3947L97.0947 63.1631L98.4078 60.721C98.5571 60.4435 98.7063 60.1659 98.911 59.9183C98.9707 59.8073 99.1157 59.6706 99.2053 59.5041C99.2608 59.5339 99.2309 59.5894 99.2864 59.6193C99.3121 59.7046 99.3676 59.7345 99.4231 59.7643C102.631 63.7788 105.934 63.6232 108.61 63.7027C109.033 63.7153 109.511 63.7577 109.904 63.8258C112.542 64.2425 114.212 65.9275 115.293 68.4408C115.084 68.8293 114.96 69.1921 114.751 69.5807Z"
      fill="#F2F8FF"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M126.973 49.108L108.768 39.3189C108.379 39.11 108.226 38.5979 108.405 38.2649L109.36 36.4888C109.569 36.1003 110.051 36.0018 110.439 36.2107L128.7 46.0296C129.088 46.2386 129.272 46.6951 129.063 47.0837L128.138 48.8042C127.818 49.1331 127.362 49.3169 126.973 49.108Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M112.888 75.3051L94.6826 65.5161C94.2941 65.3072 94.1103 64.8506 94.3192 64.4621L95.2444 62.7415C95.4533 62.353 95.9356 62.2545 96.3241 62.4634L114.585 72.2823C114.973 72.4912 115.157 72.9478 114.948 73.3363L114.023 75.0569C113.703 75.3858 113.276 75.5141 112.888 75.3051Z"
      fill="white"
      stroke="#86A6CB"
      stroke-width="2.04128"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
