export interface IStorage<T> {
  set(key: string, val: T): void;
  get(key: string): T | undefined;
  remove(key: string): void;
}

class Storage<T> implements IStorage<T> {
  set(key: string, val: T) {
    if (val === null) {
      localStorage.removeItem(key);
      return;
    }

    if (val) {
      const _val: string = JSON.stringify(val);
      localStorage.setItem(key, _val);
    }
  }

  get(key: string): T | undefined {
    const val = localStorage.getItem(key);
    if (val) {
      const _val: T = JSON.parse(val);
      return _val;
    }
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}

const storage: IStorage<string> = new Storage();

export { storage };
