
import { PropType, defineComponent } from "vue";
import { ButtonFontSize, ButtonSize, ButtonType } from "@/enums/button";
import UiCircleLoader from "@/components/ui/UiCircleLoader.vue";

export default defineComponent({
  name: "UiButton",
  components: { UiCircleLoader },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
    },
    type: {
      type: String,
    },
    buttonType: {
      type: String as PropType<ButtonType>,
      default: ButtonType.Primary,
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: ButtonSize.Medium,
    },
    fontSize: {
      type: String as PropType<ButtonFontSize>,
      default: ButtonFontSize.Medium,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    oval: {
      type: Boolean,
      default: false,
    },
    stroke: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  computed: {
    buttonClasses() {
      return {
        "ui-button": true,
        "ui-button--padding": !this.width,
        "ui-button--rounded": this.rounded,
        "ui-button--oval": this.oval,
        "ui-button--text": this.text,
        "ui-button--light": this.light,
        [`ui-button--size-${this.size}`]: true,
        [`ui-button--type-${this.buttonType}`]: true,
        [`ui-button--font-size-${this.fontSize}`]: true,
      };
    },
  },
});
