import dayjs from "dayjs";

const DATE_DEFAULT_FORMAT = "DD.MM.YYYY";

export const formatDate = (
  date?: Date | string,
  format = DATE_DEFAULT_FORMAT
) => {
  if (!date) return "-";
  return dayjs(date).format(format);
};

export const timelineFormatDate = (
  date?: Date,
  format = DATE_DEFAULT_FORMAT
) => {
  if (!date) return "--.--.---";
  return dayjs(date).format(format);
};
