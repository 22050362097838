import http from "@/services/http";
import { QueryType } from "@/services/api/clients-api";
import {
  AssessmentEmailType,
  SupplierAssessmentDetailType,
} from "@/core/types/assessments";

const resource = `${process.env.VUE_APP_API_URL}suppliers/`;

const fetchSuppliersCategories = () => http.get(`${resource}categories/`);
const fetchSuppliersAssessments = (query: QueryType) =>
  http.get(`${resource}assessments/`, { params: query }); // deprecaed
const fetchSuppliersAssessment = (): Promise<SupplierAssessmentDetailType> =>
  http.get(`${resource}assessment/`);
const readyForEvaluation = (data: AssessmentEmailType) =>
  http.post(`${resource}assessment/ready_for_evaluation/`, data);
const supplierAssessmentOpened = () =>
  http.post(`${resource}assessment/opened/`, {});
const fetchSuppliersAssessmentQuestions = (query: QueryType): Promise<any[]> =>
  http.get(`${resource}assessment_questions/`, { params: query });
const exportSuppliersAssessmentQuestions = (
  query: QueryType
): Promise<string> =>
  http.get(`${resource}assessment_questions/export/`, { params: query });
const editQuestion = (
  id: number,
  assessment_id: number,
  data: { [key: string]: string }
) =>
  http.put(`${resource}assessment_questions/${id}/`, data, {
    params: { assessment_id },
  });

const deleteQuestion = (id: number, assessment_id: number) =>
  http.delete(`${resource}assessment_questions/${id}/`, {
    params: { assessment_id },
  });

const fetchSuppliersAssesments = (query: any) =>
  http.get(`${resource}assessments/`, { params: query });

const fetchSuppliersOverview = <T>(): Promise<T> =>
  http.get(`${resource}assessments/overview/`);

export default {
  fetchSuppliersCategories,
  fetchSuppliersOverview,
  fetchSuppliersAssesments,
  fetchSuppliersAssessments,
  fetchSuppliersAssessment,
  readyForEvaluation,
  supplierAssessmentOpened,
  fetchSuppliersAssessmentQuestions,
  exportSuppliersAssessmentQuestions,
  editQuestion,
  deleteQuestion,
};
