import { UserRole } from "@/enums/user-role";

export enum ROUTER_NAMES {
  HOME = "home",
  DASHBOARD = "dashboard",
  ASSESSMENTS = "assessments",
  ASSESSMENT = "assessment",
  SUPPLIERS = "suppliers",
  PROFILE = "profile",
  CLIENT_PROFILE_NESTED_USERS = "client-profile-nested-users",
  CLIENT_PROFILE_BILLING_HISTORY = "Billing history",
  CLIENT_PROFILE_CHANGE_PASS = "Change password",
  PROFILE_EVALUATOR = "profile_evaluator",
  CONTROLS = "controls",
  QUESTIONNAIRES = "questionnaires",
  QUESTIONNAIRE = "questionnaire",
  SUPPLIER = "supplier",
  SUPPLIER_NESTED_ASSESMENT = "supplier-nested-assesment",
  SUPPLIER_NESTED_RISK_EXPOSURE = "supplier-nested-risk-exposure",
  SUPPLIER_NESTED_FUNCTIONS = "supplier-nested-functions",
  SUPPLIER_NESTED_INFORMATION = "supplier-nested-information",
  SUPPLIER_NESTED_CONTACTS = "supplier-nested-contacts",
  SUPPLIER_NESTED_LOGS = "supplier-nested-logs",

  PRIVACY = "privacy",
  CREATE_SUPPLIER = "create-supplier",
  CREATE_ASSESSMENT = "create-assessment",
  SIGN_IN = "sign-in",
  SIGN_UP = "sign-up",
  FORGOT_PASSWORD = "forgot-password",
  CREATE_PASSWORD = "create-password",
  RESET_PASSWORD = "reset-password",
  SOCIAL_AUTH = "social-auth",
  TRY_AGAIN = "try-again",
  NOT_FOUND = "not-found",

  SUPPLIER_ASSESSMENTS = "supplier-assessments",
  SUPPLIER_ASSESSMENT = "supplier-assessment",

  EVALUATOR_DASHBOARD = "evaluator-dashboard",
  EVALUATOR_ASSESSMENTS = "evaluator-assessments",
  EVALUATOR_ASSESSMENT = "evaluator-assessment",
  EVALUATOR_CLIENTS = "evaluator-clients",
  EVALUATOR_CLIENTS_CREATE = "evaluator-clients-create",
  EVALUATOR_CLIENTS_EDIT = "evaluator-clients-edit",
  EVALUATOR_QUESTIONNAIRES = "evaluator-questionnaires",
  EVALUATOR_QUESTIONNAIRE = "evaluator-questionnaire",
}

export const HOME_PAGE_BY_ROLE = {
  [UserRole.CLIENT]: ROUTER_NAMES.DASHBOARD,
  [UserRole.EVALUATOR]: ROUTER_NAMES.EVALUATOR_DASHBOARD,
};
