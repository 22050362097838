import { ROUTER_NAMES } from "@/router/router.enum";
import { ApiContractor, Config, HttpContractorIstance } from "./service.types";
import { JwtService, TokensType } from "@/services/jwt/jwt-service";
import router from "@/router";
import http from "./http";

const RefreshObservable = {
  isRefreshing: false,
  waitUntilRefreshed() {
    return new Promise((resolve) => {
      function checkIsRefreshing() {
        if (!RefreshObservable.isRefreshing) {
          clearInterval(intervalId);
          resolve(true);
        }
      }

      const intervalId = setInterval(checkIsRefreshing, 100);
    });
  },
};

class ErrorHandler {
  private error: any;

  constructor(error: any) {
    this.error = error;
  }

  get status() {
    return this.error.status;
  }

  handle(): string {
    let errorContent = this.error.data;
    if (this.error.status === 500) return (errorContent = "Server error");
    if (errorContent.detail) {
      return errorContent.detail;
    }
    if (errorContent) return errorContent;
    return "Something went wrong";
  }
}

export default class Api implements ApiContractor {
  private static instance: Api;
  private http: HttpContractorIstance;
  private jwt: JwtService;

  constructor(http: HttpContractorIstance, jwt: JwtService) {
    this.http = http;
    this.jwt = jwt;
  }

  public static getInstance(http: HttpContractorIstance, jwt: JwtService): Api {
    if (this.instance) return this.instance;
    return new Api(http, jwt);
  }

  async request(
    url: string,
    config: Config,
    withCredentials = true,
    noSupplierToken = false
  ): Promise<any> {
    const _config: Config = { ...config };
    if (!("headers" in _config)) {
      _config.headers = {};
    }
    const supplier_access = await this.fetchSupplierAccessToken();

    if (withCredentials && !supplier_access) {
      const tokens: TokensType = await this.fetchTokens();
      if (tokens.token) {
        _config.headers.authorization = "Token " + tokens.token;
      }
    }

    if (supplier_access && !noSupplierToken) {
      _config.headers.authorization = "Supplier " + supplier_access;
    }

    try {
      return await this.http.request(url, _config);
    } catch (error: any) {
      if (error.response.status === 401) {
        router.push({ name: ROUTER_NAMES.SIGN_IN });
      }
      console.log("from api", new ErrorHandler(error.response).handle());
      http.clearSupplierAccessToken();
      return Promise.reject(new ErrorHandler(error.response).handle());
    }
  }

  async get<T>(url: string, config?: Config): Promise<T> {
    const _config = {
      method: "get",
    };
    const res: Promise<T> = await this.request(url, { ...config, ..._config });
    return res;
  }

  async delete<T>(url: string, config?: Config): Promise<T> {
    const _config = {
      method: "delete",
    };
    const res: Promise<T> = await this.request(url, { ...config, ..._config });
    return res;
  }

  async post<Data, Res>(
    url: string,
    data: Data,
    config?: Config
  ): Promise<Res> {
    const _config = {
      method: "post",
      data: data,
    };
    const res: Promise<Res> = await this.request(url, {
      ...config,
      ..._config,
    });
    return res;
  }

  async put<Data, Res>(url: string, data: Data, config?: Config): Promise<Res> {
    const _config = {
      method: "put",
      data: data,
    };
    const res: Promise<Res> = await this.request(url, {
      ...config,
      ..._config,
    });
    return res;
  }

  async patch<Data, Res>(
    url: string,
    data: Data,
    config?: Config
  ): Promise<Res> {
    const _config = {
      method: "patch",
      data: data,
    };
    const res: Promise<Res> = await this.request(url, {
      ...config,
      ..._config,
    });
    return res;
  }

  async fetchTokens(): Promise<TokensType> {
    if (RefreshObservable.isRefreshing) {
      await RefreshObservable.waitUntilRefreshed();
    }
    return this.jwt.getTokens();
  }

  async fetchSupplierAccessToken(): Promise<string> {
    return this.jwt.getSupplierAccessToken().supplier_access;
  }

  setSupplierAccessToken(supplier_access: string): void {
    this.jwt.setSupplierAccessToken({ supplier_access });
  }

  clearSupplierAccessToken(): void {
    this.jwt.clearSupplierAccessToken();
  }

  async fetchTokensByCredentials(data: any): Promise<void> {
    const tokens: TokensType = await this.postWithoutCredentials(
      `${this.jwt.config.url}login/`,
      data
    );
    this.jwt.setTokens({
      ...tokens,
    });
  }

  async fetchTokensBySocialAuth(data: any): Promise<any> {
    const tokens: TokensType = await this.postWithoutCredentials(
      `${this.jwt.config.url}login_social/`,
      data
    );
    this.jwt.setTokens(tokens);
    return tokens;
  }

  async setTokens(data: any): Promise<any> {
    this.jwt.setTokens(data);
  }

  async socialAuthConnecntion(data: any): Promise<void> {
    await this.post(`${this.jwt.config.url}login_social/`, data);
  }

  async logout(): Promise<void> {
    await this.post(`${this.jwt.config.url}logout/`, {});
    this.jwt.clearTokens();
  }

  async postWithoutCredentials<Data>(
    url: string,
    payload: Data,
    config?: Config,
    noSupplierToken = false
  ) {
    const _config = {
      method: "post",
      data: payload,
    };
    return await this.request(
      url,
      { ..._config, ...config },
      false,
      noSupplierToken
    );
  }

  async getWithoutCredentials(url: string, config = {}) {
    const _config = {
      method: "get",
    };
    return await this.request(url, { ..._config, ...config }, false);
  }

  getSupplierAccessToken(): string {
    return this.jwt.getSupplierAccessToken().supplier_access;
  }
}
