type PrepareFormDataType = {
  field: string;
  file: string | Blob;
  data: { [key: string]: string };
};

export function prepareFormData({ field = "file", file, data }: PrepareFormDataType) {
  const formData = new FormData();
  if (data) {
    for (const key in data) {
      formData.append(`${key}`, data[key]);
    }
  }
  formData.append(`${field}`, file);
  return formData;
}
