import { AssessmentMoveToSupplierType } from "@/core/types/assessments";
import {
  AssessmentTypeEnum,
  EvaluationTypeEnum,
} from "@/core/enums/assessments";
import { SupplierContactType } from "@/modules/client/models/suppliers/supplier-contact.type";

type AssessmentState = {
  moveToSupplier: AssessmentMoveToSupplierType;
};

const state: AssessmentState = {
  moveToSupplier: {
    totalSteps: 4,
    currentStep: 1,
    assessmentType: AssessmentTypeEnum.SELF_MADE,
    evaluationType: EvaluationTypeEnum.SELF_EVALUATION,
    dueDate: null,
    askTeamToComplete: false,
    supplierContact: null,
    suppliersContactsList: [],
  },
};

const actions = {};

const getters = {
  showModalContact(state: AssessmentState): boolean {
    return (
      state.moveToSupplier.assessmentType ===
        AssessmentTypeEnum.SUPPLIER_MADE &&
      !state.moveToSupplier.askTeamToComplete
    );
  },
};

const mutations = {
  setStep(state: AssessmentState, value: number): void {
    state.moveToSupplier.currentStep = value;
  },
  setAssessmentType(state: AssessmentState, value: AssessmentTypeEnum): void {
    state.moveToSupplier.assessmentType = value;
  },
  setEvaluationType(state: AssessmentState, value: EvaluationTypeEnum): void {
    state.moveToSupplier.evaluationType = value;
  },
  setDueDate(state: AssessmentState, value: Date | null): void {
    state.moveToSupplier.dueDate = value;
  },
  setAskTeamToComplete(state: AssessmentState, value: boolean): void {
    state.moveToSupplier.askTeamToComplete = value;
  },
  setSupplierContact(state: AssessmentState, value: number | null): void {
    state.moveToSupplier.supplierContact = value;
  },
  setSuppliersContactsList(
    state: AssessmentState,
    value: SupplierContactType[]
  ): void {
    state.moveToSupplier.suppliersContactsList = value;
  },
  setTotalSteps(state: AssessmentState, value: number): void {
    state.moveToSupplier.totalSteps = value;
  },
  resetMoveToSupplier(state: AssessmentState): void {
    state.moveToSupplier = {
      totalSteps: 4,
      currentStep: 1,
      assessmentType: AssessmentTypeEnum.SELF_MADE,
      evaluationType: EvaluationTypeEnum.SELF_EVALUATION,
      dueDate: null,
      askTeamToComplete: false,
      supplierContact: null,
      suppliersContactsList: [],
    };
  },
};

export default {
  getters,
  state,
  mutations,
  actions,
};
