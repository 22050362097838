import { jwtConfig, JwtConfigType } from "./jwt-config";
import { IStorage, storage } from "../storage";

export type TokensType = {
  token: string;
};

export type SupplierAccessTokenType = {
  supplier_access: string;
};

export class JwtService {
  public config: JwtConfigType;
  private storage: IStorage<string>;

  constructor(storage: IStorage<string>, config: JwtConfigType) {
    this.storage = storage;
    this.config = config;
  }

  getTokens(): TokensType {
    return {
      token: storage.get("token") ?? "",
    };
  }

  getSupplierAccessToken(): SupplierAccessTokenType {
    return {
      supplier_access: storage.get("supplier_access") ?? "",
    };
  }

  setTokens(tokens: TokensType) {
    storage.set("token", tokens.token);
  }

  setSupplierAccessToken(supplier_access: SupplierAccessTokenType) {
    storage.set("supplier_access", supplier_access.supplier_access);
  }

  clearTokens() {
    storage.remove("token");
    storage.remove("supplier_access");
  }

  clearSupplierAccessToken() {
    storage.remove("supplier_access");
  }
}

const jwtService = new JwtService(storage, jwtConfig);

export { jwtService };
