import { productFruits } from 'product-fruits';

interface UserData {
  name?: string;
  email?: string;
  role?: string;
  company_name: string;
  subscription?: string;
}

export default (
  userData: UserData,
  hmac?: { hash: string; expiration: string | null | undefined }
): void => {
  if (process.env.NODE_ENV !== 'development') {
    type ProductFruitsUserObject = {
      username: string;
      firstname?: string;
      email?: string;
      role?: string;
      props?: UserCustomProps;
      hmac?: { hash: string; expiration: string | null | undefined };
    };

    if (userData?.email) {
      const userObject: ProductFruitsUserObject = {
        username: userData?.email,
        firstname: userData?.name,
        email: userData?.email,
        role: userData?.role,
        props: {
          companyName: userData?.company_name,
          subscription: userData?.subscription ?? '',
        },
      };

      if (hmac) {
        userObject.hmac = hmac;
      }

      productFruits.init(process.env.VUE_APP_PRODUCT_FRUITS, 'en', userObject);
    }
  }
};
