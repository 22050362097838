<template>
  <div
    v-show="isInit"
    class="absolute left-0 z-50 bg-white rounded-sm px-6 pb-5 pt-2 shadow-card-risk round-cropper"
    :class="[above ? '-top-1 transform -translate-y-full' : 'top-full']"
  >
    <span class="block text-sm font-semibold leading-5 text-center">
      {{ title }}
    </span>
    <div class="mt-2 rounded-8 overflow-hidden">
      <vue-cropper
        class="cropper"
        ref="cropper"
        :src="src"
        alt="Source Image"
        v-bind="options"
      />
    </div>
    <vue-slider
      class="mt-2.5"
      :process="false"
      :tooltip="'none'"
      :min="ratioProps.min"
      :max="ratioProps.max"
      interval="0.001"
      v-model="aspectRatio"
    >
    </vue-slider>
    <div class="flex gap-4 mt-3.5">
      <ui-button
        class="w-full"
        rounded
        :button-type="ButtonType.Secondary"
        @click="cancel()"
      >
        Cancel
      </ui-button>
      <ui-button
        class="w-full"
        rounded
        :button-type="ButtonType.Blue"
        @click="saveImage"
      >
        Save
      </ui-button>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import UiButton from "./UiButton.vue";
import { ref, watch } from "vue";
import { ButtonType } from "@/enums/button";

export default {
  components: {
    VueCropper,
    UiButton,
    VueSlider,
  },
  emits: ["close", "save"],
  props: {
    src: {
      type: String,
      default: "",
    },
    savingCallback: {
      type: Function,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    above: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const cropper = ref(null);
    const croppedValue = ref("");
    const isInit = ref(false);
    const ratioProps = ref({
      min: 0,
      max: 100,
    });
    const options = {
      viewMode: 0,
      dragMode: "move",
      aspectRatio: 1,
      initialAspectRatio: 250 / 140,
      checkOrientation: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      zoomOnTouch: false,
      center: false,
      toggleDragModeOnDblclick: false,
      zoomOnWheel: false,
      minContainerWidth: 250,
      minContainerHeight: 140,

      minCanvasWidth: 100,
      minCanvasHeight: 100,

      minCropBoxWidth: 140,
      minCropBoxHeight: 140,

      autoCropArea: 1,
      guides: false,
      highlight: false,
      background: false,

      ready: function () {
        const imageData = cropper.value.getImageData();
        const initialZoom =
          imageData.aspectRatio >= 1
            ? 140 / imageData.naturalWidth
            : 140 / imageData.naturalHeight;

        cropper.value.zoomTo(initialZoom);
        aspectRatio.value = +initialZoom.toFixed(3);

        ratioProps.value = {
          max: +(initialZoom * 3).toFixed(3),
          min: +(initialZoom / (140 / 100)).toFixed(3),
        };

        cropper.value.setCropBoxData({
          left: 55,
          top: 0,
          width: 140,
          heigth: 140,
        });
        isInit.value = true;
      },
    };

    const aspectRatio = ref(0);

    watch(aspectRatio, (newValue) => {
      cropper.value.zoomTo(+newValue);
    });

    function saveImage() {
      const croppedLogo = cropper.value.getCroppedCanvas({
        width: 52 * 4,
        heigth: 52 * 4,
        fillColor: "#fff",
      });

      croppedValue.value = croppedLogo.toDataURL("image/png");

      emit("save", croppedLogo.toDataURL("image/png"));

      if (props.savingCallback)
        croppedLogo.toBlob((blob) => {
          props.savingCallback(new File([blob], "logo.png"));
        }, "image/png");

      cancel();
    }

    function cancel() {
      emit("close");
    }

    return {
      options,
      aspectRatio,
      cropper,

      ButtonType,
      croppedValue,
      saveImage,
      cancel,

      ratioProps,

      isInit,
    };
  },
};
</script>

<style lang="scss" scoped>
.round-cropper::v-deep {
  .cropper-container {
    max-width: 250px;
    max-height: 140px;
  }

  .vue-slider-dot-handle {
    background: #197df2;
    border: none;
  }

  .vue-slider-rail {
    background: #ecf1f9;
  }

  .container {
    margin: 20px auto;
    max-width: 640px;
  }

  img {
    max-width: 100%;
  }

  .cropper-view-box,
  .cropper-face {
    border-radius: 50%;
  }

  .cropper-modal {
    background-color: #0c2d5e;
    opacity: 0.25;
  }

  /* The css styles for `outline` do not follow `border-radius` on iOS/Safari (#979). */
  .cropper-view-box {
    outline: 0;
    box-shadow: 0 0 0 1px rgb(44, 131, 233);
  }
}
</style>
