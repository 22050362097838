import { createStore } from "vuex";
import user from "./modules/user";
import subscription from "./modules/subscription";
import assessment from "./modules/assessment";

const state: any = {
  isShowLicenseModal: false,
  isShowTierModal: false,
  isShowSuppliersLimitModal: false,
  isShowAddSupplierModal: false,
};

export default createStore({
  state,
  mutations: {
    showLicenseModal(state) {
      state.isShowLicenseModal = true;
    },
    hideLicenseModal(state) {
      state.isShowLicenseModal = false;
    },
    showTierModal(state) {
      state.isShowTierModal = true;
    },
    hideTierModal(state) {
      state.isShowTierModal = false;
    },
    showSupplierLimitModal(state) {
      state.isShowSuppliersLimitModal = true;
    },
    hideSupplierLimitModal(state) {
      state.isShowSuppliersLimitModal = false;
    },
    showAddSupplierModal(state) {
      state.isShowAddSupplierModal = true;
    },
    hideAddSupplierModal(state) {
      state.isShowAddSupplierModal = false;
    },
  },
  actions: {},
  modules: {
    user,
    subscription,
    assessment,
  },
});
