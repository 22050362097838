<template>
  <svg
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62.3042 119.85C92.8478 119.85 117.608 94.9338 117.608 64.1982C117.608 33.4625 92.8478 8.54639 62.3042 8.54639C31.7605 8.54639 7 33.4625 7 64.1982C7 94.9338 31.7605 119.85 62.3042 119.85Z"
      fill="#F1F3F9"
      stroke="#D6DCE8"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M30.3341 13.3424C31.7952 12.8646 32.5947 11.2852 32.1198 9.81494C31.6449 8.34463 30.0755 7.54012 28.6143 8.01801C27.1532 8.4959 26.3537 10.0752 26.8286 11.5455C27.3035 13.0158 28.873 13.8203 30.3341 13.3424Z"
      fill="#EAEEF9"
    />
    <path
      d="M36.4064 4.20897C37.4 3.88401 37.9436 2.81007 37.6207 1.81027C37.2978 0.810464 36.2305 0.263397 35.237 0.58836C34.2434 0.913323 33.6998 1.98726 34.0227 2.98706C34.3456 3.98687 35.4129 4.53394 36.4064 4.20897Z"
      fill="#EAEEF9"
    />
    <path
      d="M82.043 4.41968C82.5423 3.96057 82.5771 3.18112 82.1209 2.67872C81.6646 2.17632 80.8901 2.14123 80.3908 2.60035C79.8915 3.05946 79.8567 3.83891 80.3129 4.34131C80.7692 4.84371 81.5438 4.8788 82.043 4.41968Z"
      fill="#EAEEF9"
    />
    <path
      d="M94.7971 19.0723H69.6487H28.1428C22.0226 19.5202 17.1265 24.783 17.1265 31.0536V60.0553V63.3026V97.3431C17.1265 103.95 22.4677 109.324 29.033 109.324H94.7971C101.362 109.324 106.704 103.95 106.704 97.3431V63.3026V60.0553V31.0536C106.704 24.4471 101.362 19.0723 94.7971 19.0723Z"
      fill="white"
    />
    <path
      d="M94.7971 19.0723H69.6487H28.1428C22.0226 19.5202 17.1265 24.783 17.1265 31.0536V58.5996H106.815V31.0536C106.704 24.4471 101.362 19.0723 94.7971 19.0723ZM38.8253 29.1501C37.3787 29.1501 36.2659 28.0303 36.2659 26.5746C36.2659 25.1189 37.3787 23.9992 38.8253 23.9992C40.2719 23.9992 41.3846 25.1189 41.3846 26.5746C41.2734 27.9183 40.1606 29.1501 38.8253 29.1501ZM84.2259 29.1501C82.7793 29.1501 81.6665 28.0303 81.6665 26.5746C81.6665 25.1189 82.7793 23.9992 84.2259 23.9992C85.6725 23.9992 86.7852 25.1189 86.7852 26.5746C86.6739 27.9183 85.5612 29.1501 84.2259 29.1501Z"
      fill="#F1F3F9"
      stroke="#D6DCE8"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M17.1265 60.0552V97.343C17.1265 103.95 22.4677 109.324 29.033 109.324H94.7971C101.362 109.324 106.704 103.95 106.704 97.343V60.0552H17.1265Z"
      fill="white"
      stroke="#D6DCE8"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M17.1265 63.4145L12.7867 40.6834C11.7852 35.5326 17.1265 31.1655 24.6933 31.1655H100.361C107.928 31.1655 113.158 35.5326 111.934 40.6834L106.815 63.4145"
      fill="#F1F3F9"
    />
    <path
      d="M17.1265 63.4145L12.7867 40.6834C11.7852 35.5326 17.1265 31.1655 24.6933 31.1655H100.361C107.928 31.1655 113.158 35.5326 111.934 40.6834L106.815 63.4145"
      stroke="#D6DCE8"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M56.0478 55.7273V79H53.2296V58.6818H53.0932L47.4114 62.4545V59.5909L53.2296 55.7273H56.0478ZM61.8755 74.2273V71.9091L72.1028 55.7273H73.7846V59.3182H72.6482L64.921 71.5455V71.7273H78.6937V74.2273H61.8755ZM72.8301 79V73.5227V72.4432V55.7273H75.5119V79H72.8301Z"
      fill="#79869F"
    />
  </svg>
</template>
