import type { Commit } from 'vuex';

import clientsApi from '@/services/api/clients-api';
import { SubscriptionType } from '@/core/types/subscription';

type SubscriptionState = {
  subscription: SubscriptionType | null;
  clientSubscriptionsModal: {
    show: boolean;
    title?: string | null;
    apiParams?: string | null;
  };
  subscriptionPurchaseModal: boolean;
  requestProModalPurchaseModal: boolean;
  subscriptionPurchaseErorrModal: boolean;
  clentFreeTrialModal: boolean;
  subscriptionCancelModal: boolean;
  subscriptionTrialOverModal: boolean;
  subscriptionRequestModal: boolean;
  requestProModal: boolean;
  clientSubscriptionUpdateModal: {
    show: boolean;
    plan?: any;
    preview?: any;
  };
};

export enum SubscriptionMutations {
  SET_SUBSCRIPTION = 'SET_SUBSCRIPTION',
}

const state: SubscriptionState = {
  subscription: null,
  clientSubscriptionsModal: {
    show: false,
    title: null,
    apiParams: null,
  },
  subscriptionPurchaseModal: false,
  requestProModalPurchaseModal: false,
  subscriptionPurchaseErorrModal: false,
  clentFreeTrialModal: false,
  subscriptionCancelModal: false,
  subscriptionTrialOverModal: false,
  subscriptionRequestModal: false,
  requestProModal: false,
  clientSubscriptionUpdateModal: {
    show: false,
    plan: null,
    preview: null,
  },
};

const actions = {
  async fetchSubscription({ commit }: { commit: Commit }): Promise<void> {
    const { subscription } = await clientsApi.fetchSubscription();

    if (subscription.plan?.id) {
      const subscriptionData = await clientsApi.fetchSubscriptionData();
      subscription.plan = Object.assign(subscription.plan, subscriptionData);
    } else {
      subscription.plan = {
        name: 'Limited',
      };
    }

    commit(SubscriptionMutations.SET_SUBSCRIPTION, subscription);
  },
};

const getters = {
  availableLicensesCount(state: SubscriptionState): number {
    return state.subscription?.available_licenses.reduce(
      (a: number, b: any) => a + b.count,
      0
    );
  },
  isLimited(state: SubscriptionState): boolean {
    return !state.subscription?.plan?.id;
  },
  isFreeTrial(state: SubscriptionState): boolean {
    return state.subscription?.plan.is_free_trial || false;
  },
};

const mutations = {
  [SubscriptionMutations.SET_SUBSCRIPTION](
    state: SubscriptionState,
    val: SubscriptionType
  ): void {
    state.subscription = val;
  },
  SET(
    state: SubscriptionState,
    { key, value }: { key: string; value: any }
  ): void {
    state[key as keyof SubscriptionState] = value;
  },
};

export default {
  getters,
  state,
  mutations,
  actions,
};
