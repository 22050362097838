
import { defineComponent } from "vue";
import DashboardLayout from "./dashboard.vue";
import SupplierDashboardLayout from "./supplierDashboardLayout.vue";
import EvaluatorDashboardLayout from "./evaluatorDashboardLayout.vue";
import LogoLayout from "./LogoLayout.vue";
import AuthLayout from "./auth.vue";
import SignUp from "./SignUpLayout.vue";
import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";
import { Layout } from "@/enums/layout";
export default defineComponent({
  name: "AppLayout",
  setup() {
    const layout = shallowRef<any>(null);
    const route = useRoute();
    const getLayout = (layout: Layout) => {
      switch (layout) {
        case Layout.Auth:
          return AuthLayout;
        case Layout.SignUp:
          return SignUp;
        case Layout.Dashboard:
          return DashboardLayout;
        case Layout.SupplierDashboard:
          return SupplierDashboardLayout;
        case Layout.EvaluatorDashboard:
          return EvaluatorDashboardLayout;
        case Layout.LogoLayout:
          return LogoLayout;
        default:
          return DashboardLayout;
      }
    };
    watch(
      () => route.meta,
      (meta: any) => {
        if (!meta.layout) return;
        layout.value = getLayout(meta.layout);
      },
      { immediate: true }
    );

    return { layout };
  },
});
