import axios from "axios";
import UniversalSocialauth from "universal-social-auth";

export default {
  install: (app: any) => {
    const optionsProviders = {
      providers: {
        google: {
          clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          redirectUri: `${process.env.VUE_APP_SOCIAL_REDIRECT}/social-auth/google`,
        },
        microsoft: {
          name: "microsoft",
          authorizationEndpoint:
            "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
          requiredUrlParams: ["scope"],
          scope: ["User.Read"],
          clientId: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
          redirectUri: `${process.env.VUE_APP_SOCIAL_REDIRECT}/social-auth/microsoft`,
          display: "popup",
          oauthType: "2.0",
          popupOptions: { width: 460, height: 600 },
        },
      },
    };

    const Oauth: UniversalSocialauth = new UniversalSocialauth(
      axios,
      optionsProviders
    );

    app.config.globalProperties.$Oauth = Oauth;
    app.config.globalProperties.$axios = axios;

    app.provide("socialAuth", app.config.globalProperties.$Oauth);
    app.provide("socialAuthProviders", optionsProviders.providers);
  },
};
