<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    value(val) {
      this.visible = val;
    },
  },

  data() {
    return {
      visible: this.value,
    };
  },

  methods: {
    toggle() {
      this.visible = !this.visible;
    },

    show() {
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },
  },

  render() {
    return this.$slots.default({
      visible: this.visible,
      show: this.show,
      hide: this.hide,
      toggle: this.toggle,
    });
  },
};
</script>
