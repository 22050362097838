<template>
  <ui-modal width="718" @close="$emit('close')" noScroll>
    <div class="flex flex-col items-center pt-8 pb-16 m-auto">
      <h2 class="text-center text-4.5xl leading-10 font-semibold">
        Add supplier
      </h2>

      <form class="create-form" @submit.prevent="onSubmit">
        <ui-validation-input
          v-model="supplier.company_name"
          name="company_name"
          width="370"
          height="52"
          max="100"
          rounded
          class="mt-9"
          label="Company name"
          withoutValidation
        />

        <div class="mt-4 mb-4">
          <ui-select-editable
            v-model="supplier.categories"
            :items="categoriesOptions"
            placeholder="Categories"
            optionKind="CATEGORY"
          />
        </div>

        <UiSupplierLogoWide class="mt-3" @loaded-file="addFile" />

        <ui-button
          :button-type="ButtonType.Blue"
          width="370"
          oval
          class="mt-14"
          :disabled="isSaveButtonEnabled"
        >
          Save
        </ui-button>
      </form>
    </div>
  </ui-modal>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import useShowError from "@/core/hooks/useShowError";

import UiModal from "@/components/ui/UiModal.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiValidationInput from "@/components/ui/UiValidationInput.vue";
import UiSupplierLogoWide from "@/components/ui/UiSupplierLogoWide.vue";
import { ButtonType } from "@/enums/button";
import { useRoute, useRouter } from "vue-router";
import { ROUTER_NAMES } from "@/router/router.enum";
import clientsApi from "@/services/api/clients-api";
import UiSelectEditable from "@/components/ui/UiSelectEditable.vue";
import { ErrorEnum } from "@/core/enums/errors";

export default {
  components: {
    UiSelectEditable,
    UiModal,
    UiButton,
    UiValidationInput,
    UiSupplierLogoWide,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const toast = useToast();

    const categoriesOptions = ref([]);

    const supplier = ref({
      company_name: "",
      categories: [],
      logo: null,
    });

    async function onSubmit() {
      let result = await createSupplier(supplier.value);

      if (route.query.business_unit) {
        await clientsApi.addClientSupplierToUnit(
          route.query.business_unit,
          result.id
        );
      }

      if (result?.need_upgrade) {
        emit("close");

        useShowError({
          error: ErrorEnum.PERMISSION_ERROR,
          cta: ErrorEnum.NEED_MORE_SUPPLIER,
          showToast: false,
        });
      }

      if (result?.id) {
        router.push({ name: ROUTER_NAMES.SUPPLIER, params: { id: result.id } });
        emit("close");
      }

      store.dispatch("fetchSubscription");
    }

    function addFile(fileInfo) {
      supplier.value.logo = fileInfo;
    }

    onMounted(() => {
      fetchSuppliersCategories();
    });

    async function fetchSuppliersCategories() {
      let categoriesRow = await clientsApi.fetchSuppliersCategories();
      categoriesOptions.value = categoriesRow.map((categoryRow) => ({
        label: categoryRow.value,
        value: categoryRow.id,
        is_global: categoryRow.is_global,
      }));
    }

    async function createSupplier(values) {
      try {
        if (!isLimited.value) {
          return await clientsApi.createSupplier(values);
        } else {
          useShowError({
            error: ErrorEnum.PERMISSION_ERROR,
            cta: ErrorEnum.NEED_MORE_SUPPLIER,
            showToast: false,
          });
        }
      } catch (error) {
        if (typeof error === "object") {
          const key = Object.keys(error)[0];
          const errorText = Array.isArray(error[key])
            ? error[key][0]
            : error[key];
          toast.error(errorText);
        } else {
          toast.error(error);
        }
      }
    }

    const isLimited = computed(() => store.getters.isLimited);
    const isSaveButtonEnabled = computed(() => {
      return supplier.value.company_name.trim() === "";
    });

    return {
      ButtonType,
      onSubmit,
      categoriesOptions,

      supplier,
      addFile,
      isSaveButtonEnabled,
    };
  },
};
</script>

<style lang="scss" scoped>
.create-form {
  max-width: 370px;
}
</style>
