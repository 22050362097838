import { SubscriptionTierCardType } from "@/core/types/subscription";

export default (
  tier: SubscriptionTierCardType,
  currentPlanId?: number
): string | null => {
  let classes = "limited";

  if (tier?.id) {
    classes = "basic";

    if (tier.is_free_trial) classes = "trial";
    if (tier.is_free) classes = "free";
    if (tier.color) classes = tier.color;
    if (tier.is_popular) classes = "popular";
    if (currentPlanId && tier.id === currentPlanId)
      classes = classes + " disabled";
  }

  return classes;
};
